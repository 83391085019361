import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import CaptionedImage from '../../components/CaptionedImage';
import FlowGrid from '../../components/FlowGrid';
import Instabug from '../../components/Instabug';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`Update (11/29/2020): I've updated this post to note that more subtlety is required to account for right-to-left languages.`}</p>
    </blockquote>
    <p>{`Pop quiz: in a `}<inlineCode parentName="p">{`UIButton`}</inlineCode>{`, how do you set a padding of 10pt between the image and the title?`}</p>
    <CaptionedImage max={200} filename="uibutton-padding-1.png" alt="Image showing 10pt padding between title and image of a UIButton" caption="If you're looking for a code snippet, skip to the bottom :)" mdxType="CaptionedImage" />
    <p>{`I had to do this at work recently, and I was surprised at how hard it was to reason about. There are several posts talking about this topic (including `}<a parentName="p" {...{
        "href": "https://medium.com/short-swift-stories/using-uiedgeinsets-to-layout-a-uibutton-44ba04dd085c"
      }}>{`this one`}</a>{` which uses edge insets to flip the title and image!), but the answer for my use case was pretty hard to find.`}</p>
    <p>{`Adding image-title padding is possible, but takes some trickiness with `}<inlineCode parentName="p">{`contentEdgeInsets`}</inlineCode>{`, `}<inlineCode parentName="p">{`titleEdgeInsets`}</inlineCode>{`, and `}<inlineCode parentName="p">{`imageEdgeInsets`}</inlineCode>{`.`}</p>
    <h1 {...{
      "id": "uibutton-insets"
    }}>{`UIButton Insets`}</h1>
    <p>{`To understand why adding image-title padding is hard, we have to look at UIButton's various insets properties, which control how the UIButton is drawn (in different ways).`}</p>
    <h2 {...{
      "id": "contentedgeinsets"
    }}>{`contentEdgeInsets`}</h2>
    <p><inlineCode parentName="p">{`contentEdgeInsets`}</inlineCode>{` is probably the easiest insets property to reason about. The insets apply padding to the content of the button, and they're taken into account in the button's `}<inlineCode parentName="p">{`instrinsicContentSize`}</inlineCode>{` (which was what I expected).`}</p>
    <ul>
      <li parentName="ul">{`Positive values expand the button away from its content`}</li>
      <li parentName="ul">{`Negative values contract the button towards its content`}</li>
    </ul>
    <p>{`With negative `}<inlineCode parentName="p">{`contentEdgeInsets`}</inlineCode>{`, it's possible to get the image and text to draw outside of the button's bounds.`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="content-top.gif" alt="contentEdgeInsets top" caption="Top" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="content-left.gif" alt="contentEdgeInsets left" caption="Left" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="content-bottom.gif" alt="contentEdgeInsets bottom" caption="Bottom" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="content-right.gif" alt="contentEdgeInsets right" caption="Right" mdxType="CaptionedImage" />
    </FlowGrid>
    <p><inlineCode parentName="p">{`contentEdgeInsets`}</inlineCode>{` is the closest thing available to "padding" within UIButton.`}</p>
    <h2 {...{
      "id": "imageedgeinsets"
    }}>{`imageEdgeInsets`}</h2>
    <p><inlineCode parentName="p">{`imageEdgeInsets`}</inlineCode>{` affects the drawing rect of the button's image, and does `}<em parentName="p">{`not`}</em>{` figure into the button's `}<inlineCode parentName="p">{`intrinsicContentSize`}</inlineCode>{` (this is different than `}<inlineCode parentName="p">{`contentEdgeInsets`}</inlineCode>{`, which contributes to the `}<inlineCode parentName="p">{`intrinsicContentSize`}</inlineCode>{`).`}</p>
    <ul>
      <li parentName="ul">{`Positive values contract the drawing rect towards the image's center`}</li>
      <li parentName="ul">{`Negative values expand the drawing rect away from the image's center`}</li>
    </ul>
    <p>{`Because `}<inlineCode parentName="p">{`imageEdgeInsets`}</inlineCode>{` modifies the drawing rect, setting positive values can result in a squished image - with correct insets, you can actually flip the image! Negative values have the effect of translating the image, they expand the drawing rect back instead of contracting it.`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="image-top.gif" alt="imageEdgeInsets top" caption="Top" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="image-left.gif" alt="imageEdgeInsets left" caption="Left" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="image-bottom.gif" alt="imageEdgeInsets bottom" caption="Bottom" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="image-right.gif" alt="imageEdgeInsets right" caption="Right" mdxType="CaptionedImage" />
    </FlowGrid>
    <p>{`Interestingly, `}<inlineCode parentName="p">{`UIButton`}</inlineCode>{` seems to have some issues with positive left and right edge insets - if you have an idea of why, please `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`let me know`}</a>{`.`}</p>
    <p>{`Also interestingly, negative values of equal magnitudes cancel each other out!`}</p>
    <CaptionedImage max={300} filename="uibutton-cancellout.png" alt="With all insets the same negative value, the button looks the same" caption="" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "titleedgeinsets"
    }}>{`titleEdgeInsets`}</h2>
    <p><inlineCode parentName="p">{`titleEdgeInsets`}</inlineCode>{` affects the drawing rect of the button's title, and like `}<inlineCode parentName="p">{`imageEdgeInsets`}</inlineCode>{` does `}<em parentName="p">{`not`}</em>{` contribute to the button's `}<inlineCode parentName="p">{`intrinsicContentSize`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul">{`Positive values contract the drawing rect towards the center of the title`}</li>
      <li parentName="ul">{`Negative values expand the drawing rect away from the center of the title`}</li>
    </ul>
    <p>{`As the title's drawing rect compresses (i.e., as positive insets are added), letters get cut off with `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769/status/1125608257806979073"
      }}>{`ellipses`}</a>{`. As with `}<inlineCode parentName="p">{`imageEdgeInsets`}</inlineCode>{`, negative values translate the text without otherwise affecting the button's layout.`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="title-top.gif" alt="titleEdgeInsets top" caption="Top" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="title-left.gif" alt="titleEdgeInsets left" caption="Left" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="title-bottom.gif" alt="titleEdgeInsets bottom" caption="Bottom" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="title-right.gif" alt="titleEdgeInsets right" caption="Right" mdxType="CaptionedImage" />
    </FlowGrid>
    <h2 {...{
      "id": "image-title-padding"
    }}>{`Image-title padding`}</h2>
    <p>{`Though there's no built in way to put padding between the image and the title of a `}<inlineCode parentName="p">{`UIButton`}</inlineCode>{`, we can fake it by using what we know about the various inset properties. Let's assume we want a button with 10pt padding around the content and 10pt padding between the image and the title. We'll:`}</p>
    <ol>
      <li parentName="ol">{`Add 10pt to all edges of the `}<inlineCode parentName="li">{`contentEdgeInsets`}</inlineCode></li>
      <li parentName="ol">{`Add 10pt to the left edge of the `}<inlineCode parentName="li">{`titleEdgeInsets`}</inlineCode>{` to shift the button to the right`}</li>
      <li parentName="ol">{`Add `}<em parentName="li">{`negative`}</em>{` 10pt to the `}<em parentName="li">{`right`}</em>{` edge of the `}<inlineCode parentName="li">{`titleEdgeInsets`}</inlineCode>{` since we have to make sure the button doesn't get cut off by ellipsis - adding this negative padding preserves the text's original drawing rect`}</li>
      <li parentName="ol">{`Add 10pt `}<em parentName="li">{`more`}</em>{` to the right edge of the `}<inlineCode parentName="li">{`contentEdgeInsets`}</inlineCode>{` to account for the button being moved 10pt to the right`}</li>
    </ol>
    <p>{`The final product ends up looking like this:`}</p>
    <CaptionedImage max={300} filename="uibutton-wireframe.png" alt="Wireframe explaining the incantation required to add padding between the image and title of a UIButton" caption="Magic incantation" mdxType="CaptionedImage" />
    <p>{`Once we know how to do this, we can extract it to a method:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`extension`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`UIButton`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`setInsets`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forContentPadding`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentPadding`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UIEdgeInsets,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`imageTitlePadding`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: CGFloat`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    ) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentEdgeInsets`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UIEdgeInsets`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`top`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: contentPadding.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`top`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`left`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: contentPadding.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`left`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bottom`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: contentPadding.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`bottom`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`right`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: contentPadding.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`right`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` + imageTitlePadding`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`titleEdgeInsets`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UIEdgeInsets`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`top`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`left`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: imageTitlePadding,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bottom`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`right`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: -imageTitlePadding`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <h3 {...{
      "id": "right-to-left-languages"
    }}>{`Right-to-left languages`}</h3>
    <p>{`In locales which write right-to-left, content and image insets have their right and left insets reversed, since the image is to the right of the text. In RTL environments, it's necessary to adjust the `}<inlineCode parentName="p">{`setInsets`}</inlineCode>{` method as such:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentEdgeInsets`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UIEdgeInsets`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`top`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: contentPadding.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`top`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`left`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: contentPadding.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`left`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` + imageTitlePadding,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bottom`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: contentPadding.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`bottom`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`right`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: contentPadding.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`left`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`titleEdgeInsets`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UIEdgeInsets`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`top`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`left`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: -imageTitlePadding,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bottom`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`right`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: imageTitlePadding`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`Unfortunately, `}<inlineCode parentName="p">{`UIButton`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/53987344/support-nsdirectionaledgeinsets-for-uibuttons-contenedgeinsets"
      }}>{`doesn't have support`}</a>{` for `}<inlineCode parentName="p">{`NSDirectionalEdgeInsets`}</inlineCode>{` currently, so you have to query the `}<inlineCode parentName="p">{`userInterfaceLayoutDirection`}</inlineCode>{` to figure out whether to use the right-to-left version.`}</p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Hopefully this post helps some folks wrap their heads around a `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769/status/1123798154082758656"
      }}>{`weird`}</a>{` UIKit API. If you're looking for a truly customizable `}<inlineCode parentName="p">{`UIButton`}</inlineCode>{`, don't forget that you can subclass `}<inlineCode parentName="p">{`UIControl`}</inlineCode>{` directly and use your own layout entirely - I did this for `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/trestle-the-new-sudoku/id1300230302?mt=8"
      }}>{`Trestle`}</a>{` and it worked well, even when I needed SVGs (!) inside the button.`}</p>
    <p>{`If you're interested in learning more about what I've built with UIKit (like `}<a parentName="p" {...{
        "href": "/blog/popover-uinavigationcontroller-preferredcontentsize"
      }}>{`making UINavigationController animate inside a popover`}</a>{`), you can `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://twitter.com/noahsark769"
        }}>{`follow me on Twitter`}</a></strong>{`.`}</p>
    <p>{`The code for all the examples in this post can be found at `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/NGUIButtonInsetsExample"
      }}>{`NGUIButtonInsetsExample`}</a>{`.`}</p>
    <CaptionedImage max={300} filename="uibutton-final.png" alt="Screenshot of the sample app displaying a UIButton with image-title padding." caption="" mdxType="CaptionedImage" />

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      